import ArrayModelAttribute from './core/ArrayModelAttribute';
import Attribute from './core/Attribute';
import AttributesCollection from './core/AttributeCollection';
import ModelAttribute from './core/ModelAttribute';
import { OptionAttribute } from './core/OptionAttribute';
import Model from './Model';
import Option from './Option';
import OrderComposition from './OrderComposition';
import User from './User';
import UserGift from './UserGift';

export default class Order extends Model {

  public number: string;
  public userId: number;
  public subtotal: number;
  public shippingCost: number;
  public statusId: number
  public status: Option;
  public total: number;
  public deliveryMode: Option;
  public deliveredAt: Date;
  public scheduledAt: Date;
  public user: User;
  public address: string;
  public addressComp: string;
  public cp: string;
  public city: string;
  public orderCompositions: OrderComposition[];
  public deliveryMan: string;
  public comment: string;
  public adminComment: string;
  public createdAtStr: string;
  public pit: number;
  public userGift: UserGift | null;

  static modelUrl = "orders";
  public modelName = "order";

  public attributes = new AttributesCollection({
    status: new OptionAttribute("status"),
    deliveryMode: new OptionAttribute("deliveryMode"),
    scheduledAt: new Attribute("scheduledAt"),
    deliveredAt: new Attribute("deliveredAt"),
    userId: new Attribute("userId"),
    deliveryMan: new Attribute("deliveryMan"),
    comment: new Attribute("comment"),
    adminComment: new Attribute("adminComment"),
    createdAtStr: new Attribute("createdAtStr"),
    number: new Attribute("number", {submittable: false}),
    subtotal: new Attribute("subtotal", {submittable: false}),
    shippingCost: new Attribute("shippingCost", {submittable: false}),
    total: new Attribute("total", {submittable: false}),
    user: new ModelAttribute("user", User),
    address: new Attribute("address"),
    addressComp: new Attribute("addressComp"),
    cp: new Attribute("cp"),
    city: new Attribute("city"),
    orderCompositions: new ArrayModelAttribute("orderCompositions", OrderComposition),
    pit: new Attribute('pit', {submittable: false}),
    userGift: new ModelAttribute('userGift', UserGift)
  });

  constructor(json) {
    super(json)
    console.log(json)
    this.fillAttributes(json);
  }

}